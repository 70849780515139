//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { i18n } from '@/vueI18n';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'app-audit-log-toolbar',

  computed: {
    ...mapGetters({
      hasRows: 'auditLog/hasRows',
      loading: 'auditLog/loading',
      exportLoading: 'auditLog/exportLoading',
    }),

    exportButtonDisabled() {
      return (
        !this.hasRows || this.loading || this.exportLoading
      );
    },

    exportButtonTooltip() {
      if (!this.hasRows || this.loading) {
        return i18n('common.noDataToExport');
      }

      return undefined;
    },
  },

  methods: {
    ...mapActions({
      doExport: 'auditLog/doExport',
    }),
  },
};
